.main--home_page {
	grid-area: main;
	/* display: flex; */
	flex-flow: row nowrap;
	min-height: 100%;
}

.note_editor_section {
	display: flex;
	flex-flow: column wrap;
	padding: 3rem;
	padding-bottom: 1rem;
	gap: 2.5rem;
}

.note_container {
	display: flex;
	flex-flow: column nowrap;
	width: 70rem;
}

.note_lisiting_section {
	display: flex;
	flex-flow: column nowrap;
	padding: 3rem;
	padding: 1rem 3rem;
	gap: 2rem;
	height: 100%;
}

.scroll_to_top_icon {
	position: fixed;
	bottom: 7rem;
	right: 1.5rem;
	z-index: var(--z-index-4);
}

.main--homepage {
	display: flex;
	flex-flow: column nowrap;
	padding: 2rem 1rem 1rem 0;
	gap: 3rem;
	grid-area: main;
}

.video__banner {
	width: 84%;
	aspect-ratio: 2.8/1;
	object-fit: fill;
}

.video__banner:hover {
	object-fit: fill;
}

.homepage__section {
	display: flex;
	flex-flow: column nowrap;
	gap: 2rem;
}

.categories__items {
	display: flex;
	flex-flow: row wrap;
	gap: 2rem;
	/* padding-left: 2rem; */
}

.video__items {
	display: flex;
	flex-flow: row wrap;
	gap: 2rem;
	margin-bottom: 10rem;
}

.categories__list {
	list-style-type: none;
}

@media only screen and (max-width: 780px) {
	.categories__items {
		justify-content: center;
		text-align: center;
	}

	.video__items {
		justify-content: center;
		text-align: center;
	}

	.section__heading,
	.homepage__section {
		justify-content: center;
		text-align: center;
		margin-top: 5px;
	}

	.video__banner {
		width: 98%;
		aspect-ratio: 2.8/1;
		object-fit: fill;
		margin: auto;
	}
}

.playlist__header {
	display: flex;
	justify-content: space-between;
	flex-direction: row;
	margin-top: 5px;
	padding: 0 10px;
	flex-wrap: wrap;
}

.btn__playlist {
	width: fit-content;
	padding: 5px 2rem;
}

.empty__list {
	position: fixed;
	top: 40%;
	left: 55%;
	color: var(--COLOR-EMPTY-LIST);
	transform: translate(-50%, -50%);
}
