.video_card:hover {
	box-shadow: 0px 4px 15px 0px var(--COLOR-BOX-SHADOW1);
}

.video_card {
	border: 0.2px solid var(--COLOR-CARD-BOX-SHADOW3);
	overflow: hidden;
	border-radius: 8px;
	position: relative;
}

.video_card__thumbnail {
	width: 25rem;
	aspect-ratio: 1.78/1;
}

.video_card__body {
	width: 25rem;
	padding: 8px;
	font-size: 1.6rem;
}

.video_title {
	font-size: 1.8rem;
	font-weight: 500;
	overflow: hidden;
	-webkit-line-clamp: 1;
	display: -webkit-box;
	-webkit-box-orient: vertical;
	text-overflow: ellipsis;
	white-space: normal;
	margin: 5px 0;
	cursor: default;
	text-align: left;
}

.video_card__nav {
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.video_card__nav_items {
	display: flex;
	justify-content: space-between;
	align-items: center;
	font-size: 2rem;
	box-shadow: 0;
	gap: 4px;
}

html .body .video_card__nav_items i,
html .body .video_card__nav_items button {
	font-size: 2rem;
	padding: 1.5px;
}

.video__duration {
	color: var(--COLOR-TXT);
}

.video_channel__title {
	font-size: 1.2rem;
	text-align: left;
}

.video_views_date {
	font-size: 1.5rem;
	display: flex;
	justify-content: space-between;
	margin: 5px 0;
	margin-top: 8px;
	color: var(--COLOR-TXT);
}

.video_card__cta button {
	padding: 4px;
	width: 100%;
}
.card__dismiss {
	display: grid;
	place-content: center;
	position: absolute;
	color: var(--COLOR-PRIMARY);
	top: 7px;
	right: 6px;
	backdrop-filter: blur(4px);
	z-index: var(--z-index-3);
	padding: 0;
	background-color: var(--COLOR-WHITE);
}

html main .card__dismiss i {
	font-size: 2.4rem;
}
