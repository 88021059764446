html[current_theme="light"] {
	--COLOR-BG: #e6f0ff;
	--COLOR-TXT: #000000;
	--COLOR-TXT-OPP: white;
	--COLOR-TXT-PRIMARY: #2255a4;
	--COLOR-PRIMARY-THEME: #2255a4;
	--COLOR-PRIMARY-OPP: white;
	--COLOR-OFFWHITE: #d3dded;
	--COLOR-CARD-BG: white;
	--COLOR-PRIMARY-DARK-THEME: #163d77;

	--COLOR-PRIMARY-DARK-OPP: #e6f0ff;
	--COLOR-CARD-BOX-SHADOW0: rgb(0 0 0 / 20%);
	--COLOR-CARD-BOX-SHADOW1: rgba(0, 0, 0, 0.56);
	--COLOR-CARD-BOX-SHADOW1: #0000001f;
	--COLOR-CARD-BOX-SHADOW2: #32325d1a;
	--COLOR-CARD-BOX-SHADOW3: #324dc549;
	--CARD-BOX-SHADOW: 0px 5px 20px 0px var(--COLOR-CARD-BOX-SHADOW1);

	/* Palette */
	--COLOR-NOTE-BG-RED: rgb(255, 168, 168);
	--COLOR-NOTE-BG-BLUE: rgb(138, 187, 252);
	--COLOR-NOTE-BG-GREEN: rgb(168, 255, 190);
	--COLOR-NOTE-BG-YELLOW: rgb(255, 254, 168);
	--COLOR-NOTE-BG-PURPLE: rgb(250, 186, 250);
	--COLOR-NOTE-BG-CYAN: rgb(138, 229, 252);
	--COLOR-NOTE-BG: rgb(0, 30, 75);

	--COLOR-EMPTY-LIST: #4f5359;
}

html[current_theme="dark"] {
	--COLOR-BG: #172842;
	--COLOR-TXT: white;
	--COLOR-TXT-OPP: #000000;
	--COLOR-TXT-PRIMARY: #c6ddff;
	--COLOR-PRIMARY-THEME: white;
	--COLOR-PRIMARY-DARK-THEME: #e6f0ff;
	--COLOR-PRIMARY-OPP: #2255a4;
	--COLOR-OFFWHITE: #2255a4;
	--COLOR-CARD-BG: #0c1e3a;
	--COLOR-PRIMARY-DARK-OPP: #163d77;
	--COLOR-CARD-BOX-SHADOW0: rgb(0 0 0 / 20%);
	--COLOR-CARD-BOX-SHADOW1: #ccd9faa9;
	--COLOR-CARD-BOX-SHADOW1: #4b536766;
	--COLOR-CARD-BOX-SHADOW2: #32325d1a;
	--COLOR-CARD-BOX-SHADOW3: #324dc549;
	--CARD-BOX-SHADOW: 0px 1px 1px 2px var(--COLOR-CARD-BOX-SHADOW1);

	/* Palette */
	--COLOR-NOTE-BG-RED: #670000;
	--COLOR-NOTE-BG-BLUE: #033578;
	--COLOR-NOTE-BG-GREEN: #376800;
	--COLOR-NOTE-BG-YELLOW: #4d4d00;
	--COLOR-NOTE-BG-PURPLE: #590659;
	--COLOR-NOTE-BG-CYAN: #036078;
	--COLOR-NOTE-BG: rgb(12, 19, 61);

	--COLOR-EMPTY-LIST: #5c6168;
}
