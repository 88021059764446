.filter_section {
	width: 75%;
	margin-bottom: 5px;
}

.filter_container {
	display: flex;
	flex-flow: row wrap;
	gap: 1rem;
}

.filter_button {
	width: fit-content;
	border-radius: 4px;
	padding: 2px 10px;
	cursor: pointer;
}

.sort_button {
	padding-left: 4px;
}

.btn--primary {
	border: 2px solid var(--COLOR-PRIMARY);
}

@media only screen and (max-width: 860px) {
	.filter_section {
		width: 95%;
		margin: 0 auto;
	}

	.filter_container {
		justify-content: center;
	}
}
