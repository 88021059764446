.playlist__panel {
	height: fit-content;
	width: 35rem;
	min-width: 30rem;
	background-color: var(--COLOR-BG);
	z-index: var(--z-index-3);
	border-radius: 8px;
	padding: 1rem;
	padding-top: 1.5rem;
	border: 1px solid var(--COLOR-PRIMARY-DARK-THEME);
	display: flex;
	flex-flow: column nowrap;
	gap: 1rem;
}

.playlist__form {
	justify-content: center;
	display: flex;
    flex-flow: column nowrap;
	align-items: center;
}

.playlist_header {
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.playlist_header__text {
	font-size: 2.2rem;
	padding-left: 2.3rem;
	color: var(--COLOR-TXT);
	font-weight: 500;
}

.playlist__panel .btn--dismiss {
	border: none;
	font-size: 1rem;
	padding: 4px;
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: var(--COLOR-PRIMARY);
	color: var(--COLOR-WHITE);
	transition: background-color 30ms ease-in;
}

.playlist__panel .btn--dismiss:hover {
	background-color: var(--COLOR-PRIMARY);
}

.playlist__panel .btn--dismiss i {
	font-size: 2.4rem;
}

.playlist_input {
	background-color: inherit;
	border-radius: 2px;
	border: 1px solid;
	outline: none;
	width: 85%;
	font-size: 2rem;
	padding: 2px 5px;
	color: var(--COLOR-TXT);
}

.playlist_input:focus {
	outline: 1px solid;
}

.playlist__textarea {
	font-size: 1.8rem;
	height: auto;
	resize: none;
}

.playlist__form .btn {
	font-size: 2.1rem;
	padding: 4px 6px;
	width: 6rem;
	margin: auto;
	margin-top: 5px;
}

/* Playlist listing */

.playlist__listing {
	display: flex;
	flex-flow: column nowrap;
	grid-gap: 8px;
	gap: 8px;
	color: var(--COLOR-TXT);
}

.playlist__item {
	list-style: none;
	display: flex;
	align-items: center;
	grid-gap: 7px;
	gap: 7px;
	padding-left: 2.8rem;
}

.playlist__checkbox {
	width: 1.8rem;
	height: 1.8rem;
	cursor: pointer;
}

.playlist__label {
	cursor: pointer;
	font-size: 1.7rem;
}
