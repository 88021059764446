.main--home_page {
	grid-area: main;
	flex-flow: row nowrap;
	min-height: 100%;
}

.note_editor_section {
	display: flex;
	flex-flow: column wrap;
	padding: 3rem;
	padding-bottom: 1rem;
	gap: 2.5rem;
}

.note_container {
	display: flex;
	flex-flow: column nowrap;
	width: 70rem;
}

.note_lisiting_section {
	display: flex;
	flex-flow: column nowrap;
	padding: 3rem;
	padding: 1rem 3rem;
	gap: 2rem;
	height: 100%;
}

.scroll_to_top_icon {
	position: fixed;
	bottom: 7rem;
	right: 1.5rem;
	z-index: var(--z-index-2);
}

.main--homepage {
	display: flex;
	flex-flow: column nowrap;
	padding: 2rem 1rem 1rem 0;
	gap: 3rem;
	grid-area: main;
}

.video__banner {
	width: 84%;
	aspect-ratio: 2.8/1;
	object-fit: fill;
	opacity: 0.9;
}

.video__banner:hover {
	object-fit: fill;
	transform: none;
	opacity: 1;
}

.homepage__section {
	display: flex;
	flex-flow: column nowrap;
	gap: 2rem;
}

.categories__items {
	display: flex;
	flex-flow: row wrap;
	gap: 2rem;
}

.video__items {
	display: flex;
	flex-flow: row wrap;
	gap: 2rem;
	margin-bottom: 10rem;
}

.categories__list {
	list-style-type: none;
}

@media only screen and (max-width: 780px) {
	.categories__items {
		justify-content: center;
		text-align: center;
	}

	.video__items {
		justify-content: center;
		text-align: center;
	}

	.section__heading,
	.homepage__section {
		justify-content: center;
		text-align: center;
	}

	.video__banner {
		width: 98%;
		aspect-ratio: 2.8/1;
		object-fit: fill;
		margin: auto;
	}
}
